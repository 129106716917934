const CLIENT_ID = "e9b10115-e158-4a8c-8fa9-b5cd210ee4fd";
//  "01e1aaf2-a268-4cd3-a80b-3982d0acc19d";
const TENANT_ID = "Khaadi.com";
// https://khaadi365sandbox.sandbox.operations.dynamics.com  
 
const msalConfig = {
    auth: {
      clientId: CLIENT_ID || '',
      authority: `https://login.microsoftonline.com/${TENANT_ID}`,
      redirectUri: window.location.origin || '',
      scopes: [
        'user.read'
      ],
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
    },
};

export default msalConfig;