import React, { useEffect, useContext, useState, useRef } from "react";

//axios and base url
import axios from "axios";
import { BASE_URL } from "../../../../BaseUrl";

//functions
import {
  _t,
  getCookie,
  formatPrice,
  currencySymbolRight,
  getSystemSettings,
} from "../../../../functions/Functions";
import { useTranslation } from "react-i18next";

//3rd party packages
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import Moment from "react-moment";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//pages & includes
import ReportSidebar from "./ReportSidebar";

//context consumer
import { SettingsContext } from "../../../../contexts/Settings";
import { RestaurantContext } from "../../../../contexts/Restaurant";
import { UserContext } from "../../../../contexts/User";

const SupplierLedgerReport = () => {
  const { t } = useTranslation();
  //getting context values here
  let { loading, setLoading, dataPaginating, generalSettings } =
    useContext(SettingsContext);
  let { branchForSearch } = useContext(RestaurantContext);
  const { authUserInfo, supplierForSearch, getSupplier } = useContext(UserContext);

  let [newSupplierLedgerReport, setNewSupplierLedgerReport] = useState({
    branch: null,
    supplier: null,
  });


  //all data
  const [reportData, setReportData] = useState(null);
  const [reportSummary, setReportSummary] = useState(null)

  const componentRef = useRef();
  const componentRef1 = useRef();
  //settle order
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [generatedReport, setGeneratedReport] = useState(false);
  const [generatedSummary, setGeneratedSummary] = useState(false)
  const [siteName, setSiteName] = useState("");
  //useEffect == componentDidMount()
  const [branchSearch, setBranchSearch] = useState(null);

  useEffect(() => {
    setSiteName(getSystemSettings(generalSettings, "siteName"));
    if (authUserInfo.details.user_type === "staff" || authUserInfo.details.user_type === "manager") {
      setBranchSearch(
        branchForSearch.filter(
          (branch) => branch.id === authUserInfo.details.branch_id
        )
      );
    } else {
      setBranchSearch(branchForSearch);
    }
    getSupplier();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [generalSettings]);

  const handleBranch = (branch) => {
    setNewSupplierLedgerReport({
      ...newSupplierLedgerReport,
      branch,
    });
  };

  const handleSupplier = (supplier) => {
    
    setNewSupplierLedgerReport({
      ...newSupplierLedgerReport,
      supplier,
    })

  };

  //get BranchWise reports filter
  const getBranchWiseSelected = () => {
  
    if (newSupplierLedgerReport?.branch?.id !== null && newSupplierLedgerReport?.branch?.id !== undefined && startDate !== null && endDate !== null) {
      setLoading(true);

      const fromDate = moment(startDate).format("YYYY-MM-DD");
      const toDate = moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-supplier-payment-report";

      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("supplier_id", newSupplierLedgerReport.supplier?.id ? newSupplierLedgerReport.supplier?.id : "");
      formData.append("branch_id", newSupplierLedgerReport?.branch?.id);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
         
          setReportData(res.data[0]);

          setLoading(false);
          setGeneratedReport(true);
          componentRef.current.handleDownload();
          setNewSupplierLedgerReport({
            branch: null,
            supplier: null,
          });
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, to date and branch"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };

  //get BranchWise summary filter
  const getBranchWiseSelectedSummary = () => {
    
    if (newSupplierLedgerReport?.branch?.id !== null && newSupplierLedgerReport?.branch?.id !== undefined && startDate !== null && endDate !== null) {
      setLoading(true);

      const fromDate = moment(startDate).format("YYYY-MM-DD");
      const toDate = moment(endDate).format("YYYY-MM-DD");

      const url = BASE_URL + "/settings/get-supplier-payment-summary";

      let formData = new FormData();
      formData.append("fromDate", fromDate);
      formData.append("toDate", toDate);
      formData.append("suppplier_id", newSupplierLedgerReport.supplier?.id ? newSupplierLedgerReport.supplier?.id : "");
      formData.append("branch_id", newSupplierLedgerReport.branch?.id);

      return axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${getCookie()}` },
        })
        .then((res) => {
        
          setReportSummary(res.data[0]);

          setLoading(false);
          setGeneratedSummary(true);
          componentRef1.current.handleDownload();
          setNewSupplierLedgerReport({
            branch: null,
            supplier: null,
          });
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      toast.error(
        `${_t(t("Please select from date, to date and branch"))}`,
        {
          position: "bottom-center",
          closeButton: false,
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          className: "text-center toast-notification",
        }
      );
    }
  };


  return (
    <>
      <Helmet>
        <title>{_t(t("Supplier Ledger Report"))}</title>
      </Helmet>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="table-to-xls"
        filename="Supplier Ledger Detail Report"
        sheet="sheet 1"
        buttonText="Supplier Report"
        ref={componentRef}
      />

      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        className="download-table-xls-button btn btn-success float-right mr-3 invisible"
        table="summary-to-xls"
        filename="Supplier Ledger Summary Report"
        sheet="sheet 1"
        buttonText="supplier summary"
        ref={componentRef1}
        />

      {/* Print Button detail*/}
      <div className="d-none">
        <div>
          <style type="text/css" media="print">
            {
              "\
          @page { size: landscape; }\
        "
            }
          </style>
          <div className="fk-print">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  {reportData !== null &&
                    reportData !== undefined &&
                    generatedReport === true && (
                      <>
                        <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                          <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                            <table
                              className="table table-bordered table-hover min-table-height mt-3"
                              id="table-to-xls"
                            >
                              <div className="page-break"></div>
                              <thead className="align-middle">
                                <tr style={{ border: "0px" }}>
                                  <th colspan="9" style={{ border: "0px" }}>
                                    <div className="row">
                                      <div className="col-12">
                                        <h3 className="text-center mb-0">
                                          {siteName}
                                        </h3>
                                        <h3 className="text-center mt-0">
                                          Supplier Ledger Report
                                        </h3>
                                        <p className="text-center">
                                          FROM&nbsp;
                                          <Moment format="LL">
                                            {startDate}
                                          </Moment>
                                          &nbsp;To&nbsp;
                                          <Moment format="LL">
                                            {endDate}
                                          </Moment>
                                        </p>
                                        <p className="text-center">
                                           {newSupplierLedgerReport.branch?.name}
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                </tr>
                                <tr>
                                <th
                                 scope="col"
                                >
                                 {_t(t("Supplier Name"))}
                                </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("S/L"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Document Date"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Document No"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Checque No"))}
                                  </th>
                                 
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Debit"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Credit"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Balance"))}
                                  </th>
                                  <th
                                    scope="col"
                                  >
                                    {_t(t("Remarks"))}
                                  </th>
                                
                                </tr>
                              </thead>
                              <tbody className="align-middle">
                                {/* loop here*/}
                                {
                                  Object.keys(reportData).map(
                                    (group_name, index) => {
                                      
                                      return(
                                      <div key={index}>
                                    
                                      <tr>
                                      <th
                                        scope="row"
                                      >
                                        {group_name}
                                      </th>
                                    </tr>
                                {reportData[group_name].map((item, i) => {
                                  return (
                                    <tr>
                                     <td></td>
                                      <td
                                        scope="row"
                                      >
                                        {i + 1}
                                      </td>
                                      <td
                                        scope="row"
                                      >
                                      <Moment format="LL">

                                        {item.document_date}
                                        </Moment>
                                      </td>
                                      <td
                                        scope="row"
                                      >
                                        {item.document_no}
                                      </td>
                                      <td
                                        scope="row"
                                      >
                                        {item.cheque_no}
                                      </td>
                                      <td
                                        scope="row"
                                      >
                                        {item.debit}
                                      </td>
                                      <td
                                        scope="row"
                                      >
                                        {item.credit}
                                      </td>
                                      <th
                                        scope="row"
                                      >
                                        {item.balance >= 0 ? item.balance : `(${ Math.abs(item.balance)})`}
                                      </th>
                                      <td
                                        scope="row"
                                      >
                                        {item.remarks}
                                      </td>
                                    </tr>
                                  );
                                })}
                                </div>
                                );
                              }
                              )
                             }
                              </tbody>

                              <tfoot style={{ border: "0px" }}>
                                <tr style={{ border: "0px" }}>
                                  <td
                                    colspan="7"
                                    className="text-center"
                                    style={{ border: "0px" }}
                                  >
                                    <h5 className="mt-3">
                                      Powered by indolj.pk
                                    </h5>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

       {/* Print Button  summary*/}
       <div className="d-none">
       <div>
         <style type="text/css" media="print">
           {
             "\
         @page { size: landscape; }\
       "
           }
         </style>
         <div className="fk-print">
           <div className="container">
             <div className="row">
               <div className="col-12">
                 {reportSummary !== null &&
                    reportSummary !== undefined &&
                   generatedSummary === true && (
                     <>
                       <div className="row gx-2 justify-content-center t-pb-15 t-pt-15 report">
                         <div className="col-12 t-mb-15 mb-md-0 table-responsive">
                           <table
                             className="table table-bordered table-hover min-table-height mt-3"
                             id="summary-to-xls"
                           >
                             <div className="page-break"></div>
                             <thead className="align-middle">
                               <tr style={{ border: "0px" }}>
                                 <th colspan="3" style={{ border: "0px" }}>
                                   <div className="row">
                                     <div className="col-12">
                                       <h3 className="text-center mb-0">
                                         {siteName}
                                       </h3>
                                       <h3 className="text-center mt-0">
                                         Supplier Ledger Summary Report
                                       </h3>
                                       <p className="text-center">
                                         FROM&nbsp;
                                         <Moment format="LL">
                                           {startDate}
                                         </Moment>
                                         &nbsp;To&nbsp;
                                          <Moment format="LL">
                                            {endDate}
                                          </Moment>
                                       </p>
                                       <p className="text-center">
                                          {newSupplierLedgerReport.branch?.name}
                                       </p>
                                     </div>
                                   </div>
                                 </th>
                               </tr>
                               <tr>
                                 <th
                                   scope="col"
                                   className="sm-text text-capitalize align-middle text-center border-1 border"
                                 >
                                   {_t(t("S/L"))}
                                 </th>
                                 <th
                                   scope="col"
                                   className="sm-text text-capitalize align-middle text-center border-1 border"
                                 >
                                   {_t(t("Supplier Name"))}
                                 </th>
                                 <th
                                   scope="col"
                                   className="sm-text text-capitalize align-middle text-center border-1 border"
                                 >
                                   {_t(t("Total"))}
                                 </th>
                                                               
                               </tr>
                             </thead>
                             <tbody className="align-middle">
                               {console.log("309 ", reportData)}
                               {/* loop here*/}
                               {reportSummary.map((item, i) => {
                                 
                                 return (
                                   <tr>
                                     <td
                                       scope="row"
                                       className="xsm-text text-capitalize align-middle text-center "
                                     >
                                       {i + 1}
                                     </td>
                                     <td
                                       scope="row"
                                       className="xsm-text text-capitalize align-middle text-center"
                                     >
                                      {item.supplier_name}
                                     </td>
                                     <td
                                       scope="row"
                                       className="xsm-text text-capitalize align-middle text-center"
                                     >
                                       {item.total}
                                     </td>
                                   </tr>
                                 );
                               })}
                             </tbody>

                             <tfoot style={{ border: "0px" }}>
                               <tr style={{ border: "0px" }}>
                                 <td
                                   colspan="3"
                                   className="text-center"
                                   style={{ border: "0px" }}
                                 >
                                   <h5 className="mt-3">
                                     Powered by indolj.pk
                                   </h5>
                                 </td>
                               </tr>
                             </tfoot>
                           </table>
                         </div>
                       </div>
                     </>
                   )}
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>

      {/* main body */}
      <main id="main" data-simplebar>
        <div className="container-fluid">
          <div className="row t-mt-10 gx-2">
            {/* left Sidebar */}
            <div className="col-lg-2 col-xxl-2 t-mb-30 mb-lg-0">
              <ReportSidebar />
            </div>
            {/* left Sidebar ends */}

            {/* Rightbar contents */}
            <div className="col-lg-10 col-xxl-10 t-mb-2 mb-lg-0">
              <div className="t-bg-white p-1">
                <div
                  className="fk-scroll--pos-menu table-bottom-info-hide"
                  data-simplebar
                >
                  <div className="t-pl-15 t-pr-15">
                    {/* next page data spin loading */}
                    <div className={`${(dataPaginating || loading) && "loadingBlur"}`}></div>
                    <div className={`${(dataPaginating || loading) && "lds-roller"}`}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    {/* spin loading ends */}

                 
                     <>
                      <div key="smtp-form mt-2">
                        <div className="row mt-1 gx-2 align-items-center t-pt-2 t-pb-2">
                          <div className="col-12 t-mb-15 mb-md-0">
                            <ul className="t-list fk-breadcrumb">
                              <li className="fk-breadcrumb__list">
                                <span className="t-link fk-breadcrumb__link text-capitalize mt-2">
                                  {_t(t("Supplier Ledger Report"))}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-2">
                            <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control"
                              placeholderText={_t(t("From Date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>
                          <div className="col-2">
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              className="form-control"
                              placeholderText={_t(t("To Date"))}
                              shouldCloseOnSelect={false}
                            />
                          </div>

                          <div className="col-2">
                            <Select
                                options={branchSearch ? branchSearch : []}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isClearable
                                value={newSupplierLedgerReport.branch}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleBranch}
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select branch")) + ".."}
                            />
                          </div>

                          <div className="col-2">
                            <Select
                                options={supplierForSearch ? supplierForSearch : []}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                isClearable
                                value={newSupplierLedgerReport.supplier}
                                classNamePrefix="select"
                                className="table-text"
                                onChange={handleSupplier}
                                maxMenuHeight="200px"
                                placeholder={_t(t("Select supplier")) + ".."}
                            />
                          </div>
                        
                          <div className="col-2">
                            <button
                              className="btn btn-primary sm-text text-capitalize mr-2 px-2 py-2 ml-2 rounded w-100"
                              onClick={(e) => getBranchWiseSelected(e)}
                            >
                              SUPPLIER DETAIL
                            </button>
                          </div>
                          <div className="col-2">
                            <button
                              className="btn btn-primary sm-text px-2 mr-2 rounded py-2 text-capitalize w-100"
                              onClick={(e) => getBranchWiseSelectedSummary(e)}
                            >
                              SUPPLIER SUMMARY
                            </button>
                           </div> 
                                                     
                        </div>
                      </div>

                     </>
                  </div>
                </div>
              </div>
              
            </div>
            {/* Rightbar contents end*/}
          </div>
        </div>
      </main>
      {/* main body ends */}
    </>
  );
};

export default SupplierLedgerReport;
